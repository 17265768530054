import {hasError} from '../../selectors/events'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {Events as EventsPresentation} from './events'

const mapRuntime = ({state}: MemberPageContext) => ({
  error: hasError(state),
})

export const Events = connect(mapRuntime)(EventsPresentation)
