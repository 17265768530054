import React from 'react'
import {ErrorState} from '../error-state'
import {EventsList} from '../events-list'
import {EventsTabs} from '../events-tabs'
import {Header} from '../header'

export const Events = ({error}: {error: boolean}) => {
  if (error) {
    return <ErrorState />
  }
  return (
    <>
      <Header />
      <EventsTabs />
      <EventsList />
    </>
  )
}
